import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useSubscriptionService() {
  const { axiosInstance } = useAxiosConfig();

  // Endpoint para trae el historial de suscriprip por user id
  const getSubscriptionsHistory = async () => {
    try {
      const subscriptionsHistory = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/vendedor/subscription/history`,
      });
      return subscriptionsHistory.data.data.subscriptionHistory;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const getSubscription = async subscriptionId => {
    try {
      const response = await axiosInstance.get(
        `${baseURL}/api/vendedor/subscription/single-subscription/${subscriptionId}`
      );

      let subscription = response.data.data.subscription;

      if (Object.keys(subscription).length === 0) {
        subscription = null;
      }
      return subscription;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const getSubscriptions = async (limit, offset, status) => {
    try {
      const subscriptions = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/vendedor/subscription/all`,
        params: {
          limit,
          offset,
          status,
        },
      });
      return subscriptions.data.data.subscriptions;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const getSubscriptionsQuantity = async status => {
    try {
      const subscriptions = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/vendedor/subscription/quantity`,
        params: {
          status,
        },
      });
      return subscriptions.data.data.subscriptions_quantity;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  return {
    getSubscriptionsHistory,
    getSubscriptionsQuantity,
    getSubscriptions,
    getSubscription,
  };
}

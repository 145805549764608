import React, { createContext, useContext, useState } from 'react';
import useSaleService from '../services/saleService';

const SaleContext = createContext(null);

export const SaleProvider = ({ children }) => {
  const [sales, setSales] = useState([]);
  const [limitSales, setLimitSales] = useState([]);
  const [salesQuantity, setSalesQuantity] = useState(0);
  const SaleService = useSaleService();

  const getSale = async paymentId => {
    try {
      const sale = await SaleService.getSale(paymentId);
      return sale;
    } catch (error) {
      console.error('Hubo un error al intentar obtener la venta: ', error);
    }
  };

  const getSales = async () => {
    try {
      const allSales = await SaleService.getSales();
      setSales(allSales);
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener todas las ventas: ',
        error
      );
    }
  };

  const getLimitSales = async (limit, offset) => {
    try {
      const limitSales = await SaleService.getSales(limit, offset);
      setLimitSales(limitSales);
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener la pagina de ventas: ',
        error
      );
    }
  };

  const getSalesQuantity = async () => {
    try {
      const salesQuantity = await SaleService.getSalesQuantity();
      setSalesQuantity(salesQuantity);
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener la pagina de ventas: ',
        error
      );
    }
  };

  return (
    <SaleContext.Provider
      value={{
        getSale,
        getSales,
        getLimitSales,
        getSalesQuantity,
        sales,
        salesQuantity,
        setSalesQuantity,
        limitSales,
        setLimitSales,
      }}
    >
      {children}
    </SaleContext.Provider>
  );
};

export const useSale = () => useContext(SaleContext);

export default SaleProvider;

import React, { createContext, useState, useContext, useEffect } from 'react';
import decodeJWT from '../utils/decodeJTW';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storageToken = localStorage.getItem('token');
        const userdata = localStorage.getItem('user');
        if (storageToken && userdata) {
            setIsAuthenticated(true);
            setToken(storageToken);
            setUser(JSON.parse(userdata));
        }
    }, []);

    const login = (token) => {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(decodeJWT(token).payload));
        setIsAuthenticated(true);
        setToken(token);
        console.log('JWT token:', token);  // Log the JWT when a user logs in
    };

    const getToken = async () => {
        if (token) {
            return token;
        }
        const storageToken = localStorage.getItem('token');
        if (storageToken) {
            setToken(storageToken);
            return storageToken;
        }
        return null;
    };

    const getUser = async () => {
        if (user) {
            return user;
        }
        const userdata = localStorage.getItem('user');
        if (userdata) {
            setUser(JSON.parse(userdata));
            return JSON.parse(userdata);
        }
        return null;
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setToken(null);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, getToken, getUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

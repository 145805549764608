import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useCatalogService() {
  const { axiosInstance } = useAxiosConfig();

  const getCatalog = async () => {
    try {
      const response = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/catalog`,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const updateCatalog = async (catalog, data) => {
    try {
      const response = await axiosInstance({
        method: 'PATCH',
        url: `${baseURL}/api/catalog`,
        data: {
          catalog,
          data,
        },
      });
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  return {
    getCatalog,
    updateCatalog,
  };
}

import React, { createContext, useContext, useState } from 'react';
import useProductService from '../services/productService';

const ProductContext = createContext(null);

export const ProductProvider = ({ children }) => {
  const [product, setProduct] = useState([]);
  const productService = useProductService();

  const getProducts = async () => {
    try {
      const res = await productService.getProducts();
      if (res.status === 'success') {
        setProduct(Array.isArray(res.data) ? res.data : []);
      } else {
        setProduct([]);
      }
    } catch (error) {
      console.log('Hubo un error al intentar obtener los productos: ', error);
      setProduct([]);
    }
  };

  const addProduct = async (formData) => {
    try {
      await productService.addProduct(formData);
      await getProducts();
    } catch (error) {
      console.log('Hubo un error al agregar un producto: ', error);
    }
  };

  const editProduct = async (sku, formData) => {
    try {
      await productService.editProduct(sku, formData);
      await getProducts();
    } catch (error) {
      console.log('Hubo un error al editar un producto: ', error);
    }
  };

  const deleteProduct = async (sku) => {
    try {
      await productService.deleteProduct(sku);
      await getProducts();
    } catch (error) {
      console.log('Hubo un error al eliminar el producto con SKU: ', sku);
    }
  }

  return (
    <ProductContext.Provider value={{ getProducts, product, addProduct, editProduct, deleteProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);

export default ProductProvider;
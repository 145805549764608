import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Input,
    useToast,
} from '@chakra-ui/react';
import { useCoupon } from '../../contexts/couponContext';

const DeleteCouponModal = ({ isOpen, onClose, couponId, fetchCoupons }) => {
    const { deleteCoupon } = useCoupon();
    const toast = useToast();
    const [confirmationText, setConfirmationText] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        try {

            const success = await deleteCoupon(couponId);

            if (success === false) {
                toast({
                    title: 'Error al eliminar',
                    description: 'Hubo un problema al eliminar el cupón.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Cupón eliminado',
                    description: 'El cupón ha sido eliminado exitosamente.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            onClose();
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
            fetchCoupons();
        }
    };

    useEffect(() => {
        setConfirmationText('');
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Eliminar Cupón</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={3}>
                        ¿Estás seguro de que deseas eliminar este cupón? Esta acción no se puede deshacer y puede afectar a usuarios que estén utilizando el cupón actualmente.
                    </Text>
                    <Text mb={3} fontWeight="bold">
                        Escribe "ELIMINAR" en la casilla de abajo para confirmar:
                    </Text>
                    <Input
                        placeholder="Escribe ELIMINAR"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="red"
                        ml={3}
                        onClick={handleDelete}
                        isDisabled={confirmationText !== 'ELIMINAR'}
                        isLoading={loading}
                    >
                        Eliminar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteCouponModal;

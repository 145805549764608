import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Button,
  Input,
  Box,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useBreakpointValue
} from '@chakra-ui/react';
import { CatalogTableRow } from '../molecules/CatalogTableRow';

export const CatalogTable = ({ catalogName, elements, updateCatalog }) => {
  const [catalogElements, setCatalogElements] = useState(elements);
  const [editingElement, setEditingElement] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newElementName, setNewElementName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();
  const [elementToDelete, setElementToDelete] = useState(null);

  const handleAddElement = () => {
    if (newElementName.trim() === '') return;

    setCatalogElements([...catalogElements, newElementName]);
    setNewElementName('');
  };

  const handleUpdateElement = (updatedElement) => {
    const updatedElements = catalogElements.map((element, index) =>
      index === editingIndex ? updatedElement : element
    );
    setCatalogElements(updatedElements);
    setEditingElement(null);
    setEditingIndex(null);
  };

  const handleConfirmDelete = () => {
    setCatalogElements(catalogElements.filter(e => e !== elementToDelete));
    setElementToDelete(null);
    onDeleteClose();
  };

  const handleDeleteElement = (element) => {
    setElementToDelete(element);
    onDeleteOpen();
  };

  const handleSaveChanges = () => {
    updateCatalog(catalogElements, catalogName);
    onClose();
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const buttonFontSize = useBreakpointValue({ base: '12px', md: '14px' });
  const addText = useBreakpointValue({ base: 'Agregar', md: 'Agregar Elemento' });
  const saveText = useBreakpointValue({ base: 'Guardar', md: 'Guardar Cambios' });

  return (
    <Box p={5} m={5}>
      <Heading as="h1" size="lg" textAlign={"center"}>
        {capitalizeFirstLetter(catalogName)}
      </Heading>
      <Flex mt={4} mb={4} align="center">
        <Input
          placeholder="Nombre del Nuevo Elemento"
          value={newElementName}
          onChange={(e) => setNewElementName(e.target.value)}
        />
        <Button mx={3} px="30px" colorScheme="teal" fontSize={buttonFontSize} onClick={handleAddElement}>
          {addText}
        </Button>
        <Button px="30px" colorScheme="blue" fontSize={buttonFontSize} onClick={handleSaveChanges}>
          {saveText}
        </Button>
      </Flex>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Elemento</Th>
              <Th textAlign={'right'}>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {catalogElements.map((element, index) => (
              <CatalogTableRow
                key={index}
                element={element}
                updateElement={handleUpdateElement}
                deleteElement={handleDeleteElement}
                setEditingElement={(element) => {
                  setEditingElement(element);
                  setEditingIndex(index);
                }}
                onOpen={onOpen}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Elemento</ModalHeader>
          <ModalBody>
            {editingElement && (
              <Input
                placeholder="Nombre del Elemento"
                value={editingElement}
                onChange={(e) => setEditingElement(e.target.value)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" fontSize={buttonFontSize} onClick={() => {
              handleUpdateElement(editingElement);
              onClose();
            }}>
              Modificar
            </Button>
            <Button ml={3} fontSize={buttonFontSize} onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Eliminación</ModalHeader>
          <ModalBody>
            ¿Estás seguro de que quieres eliminar este elemento?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" fontSize={buttonFontSize} onClick={handleConfirmDelete}>
              Eliminar
            </Button>
            <Button ml={3} fontSize={buttonFontSize} onClick={onDeleteClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

import React, { createContext, useContext, useState } from 'react';
import useCouponService from '../services/couponService';

const CouponContext = createContext(null);

export const CouponProvider = ({ children }) => {
    const [limitCoupons, setLimitCoupons] = useState([]);
    const [couponsQuantity, setCouponsQuantity] = useState(0);
    const CouponService = useCouponService();

    const getLimitCoupons = async (limit, offset, foundationType) => {
        try {
            const limitCoupons = await CouponService.getLimitCoupons(
                limit,
                offset,
                foundationType
            );
            setLimitCoupons(limitCoupons);
        } catch (error) {
            console.error(
                'Hubo un error al intentar obtener la pagina de cupones: ',
                error
            );
        }
    };

    const getCoupons = async (foundationType, searchedFoundation) => {
        try {
            const couponsFounded = await CouponService.getCoupons(
                searchedFoundation,
                foundationType
            );
            return couponsFounded;
        } catch (error) {
            console.error(
                'Hubo un error al buscar el cupon por fundación: ',
                error
            );
        }
    };

    const getCouponsQuantity = async foundationType => {
        try {
            const quantity = await CouponService.getCouponsQuantity(
                foundationType
            );
            setCouponsQuantity(quantity);
        } catch (error) {
            console.error(
                'Hubo un error al intentar obtener la cantidad de cupones: ',
                error
            );
        }
    };

    const getCouponCodes = async couponId => {
        try {
            const codes = await CouponService.getCouponCodes(couponId);
            return codes;
        } catch (error) {
            console.error(
                'Hubo un error al obtener los codigos del cupon: ',
                error
            );
        }
    };

    const updateCoupon = async (couponId, updatedCoupon) => {
        try {
            const response = await CouponService.updateCoupon(couponId, updatedCoupon);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar actualizar el cupon: ',
                error
            );
        }
    };

    const deleteCoupon = async couponId => {
        try {
            const response = await CouponService.deleteCoupon(couponId);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar eliminar el cupon: ',
                error
            );
        }
    };

    const createCoupon = async coupon => {
        try {
            const response = await CouponService.createCoupon(coupon);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar crear el cupon: ',
                error
            );
        }
    };

    return (
        <CouponContext.Provider
            value={{
                getLimitCoupons,
                getCouponCodes,
                createCoupon,
                getCouponsQuantity,
                getCoupons,
                updateCoupon,
                deleteCoupon,
                couponsQuantity,
                setCouponsQuantity,
                limitCoupons,
                setLimitCoupons,
            }}
        >
            {children}
        </CouponContext.Provider>
    );
};

export const useCoupon = () => useContext(CouponContext);

export default CouponProvider;
import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useProductService() {
    const { axiosInstance } = useAxiosConfig();
    const productURL = baseURL + "/api/store";

    const getProducts = async () => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${productURL}/all`
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };

    const addProduct = async (formData) => {
        try {
            const response = await axiosInstance({
                method: 'POST',
                url: `${productURL}/`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };

    const editProduct = async (sku, formData) => {
        try {
            const response = await axiosInstance({
                method: 'PATCH',
                url: `${productURL}/?sku=${sku}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };

    const deleteProduct = async (sku) => {
        try {
            const response = await axiosInstance({
                method: 'DELETE',
                url: `${productURL}?sku=${sku}`
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };

    return {
        getProducts,
        addProduct,
        deleteProduct,
        editProduct
    };
}

import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Select,
    useToast,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { useCoupon } from '../../contexts/couponContext';

const UpdateCouponModal = ({ isOpen, onClose, coupon, fetchCoupons }) => {
    const { updateCoupon } = useCoupon();
    const [formData, setFormData] = useState({
        expires_at: '',
        tipo_de_fundacion: '',
        fundacion: '',
        via_fundacion: '',
    });
    const [loading, setLoading] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);
    const toast = useToast();

    useEffect(() => {
        if (isOpen && coupon) {
            setFormData({
                expires_at: coupon.expires_at || '',
                tipo_de_fundacion: coupon.tipo_de_fundacion || '',
                fundacion: coupon.fundacion || '',
                via_fundacion: coupon.via_fundacion || '',
            });
        }
    }, [isOpen, coupon]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'expires_at') {
            const selectedDate = new Date(value);
            const today = new Date();
            setIsDateValid(selectedDate > today);
        }
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const updatedCoupon = {
                expires_at: formData.expires_at || undefined,
                tipo_de_fundacion: formData.tipo_de_fundacion || undefined,
                fundacion: formData.fundacion || undefined,
                via_fundacion: formData.via_fundacion || undefined,
            };

            const success = await updateCoupon(coupon._id, updatedCoupon);

            if (success === false) {
                toast({
                    title: 'Error al actualizar',
                    description: 'Hubo un problema al actualizar el cupón.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Cupón actualizado',
                    description: 'El cupón ha sido actualizado exitosamente.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            onClose();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            fetchCoupons();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Actualizar Cupón</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Fecha de Expiración</FormLabel>
                        <Input
                            type="date"
                            name="expires_at"
                            value={formData.expires_at}
                            onChange={handleChange}
                            isInvalid={!isDateValid && formData.expires_at}
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Tipo de Fundación</FormLabel>
                        <Select
                            name="tipo_de_fundacion"
                            value={formData.tipo_de_fundacion}
                            onChange={handleChange}
                        >
                            <option value="gob">Gobierno</option>
                            <option value="empresa">Empresa</option>
                            <option value="otra">Otra</option>
                        </Select>
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Fundación</FormLabel>
                        <Input
                            type="text"
                            name="fundacion"
                            value={formData.fundacion}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Vía Fundación</FormLabel>
                        <Input
                            type="text"
                            name="via_fundacion"
                            value={formData.via_fundacion}
                            onChange={handleChange}
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleUpdate}
                        isLoading={loading}
                        ml={3}
                        isDisabled={!isDateValid}
                    >
                        Actualizar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UpdateCouponModal;

import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useTutorialService() {
    const { axiosInstance } = useAxiosConfig();

    const getTutorials = async () => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/tutorials/`
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };

    const addTutorials = async (file, title, description, duration, order) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', title);
            formData.append('description', description);
            formData.append('duration', duration);
            formData.append('order', order);
            console.log(formData.get('file'));
            const response = await axiosInstance({
                method: 'POST',
                url: `${baseURL}/api/tutorials/`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    };
    
    const editTutorials = async(file = null, title, description, duration, order, id) => {
        try {
            const formData = new FormData();
            if(file){
                formData.append('file', file);
            }
            formData.append('title', title);
            formData.append('description', description);
            formData.append('duration', duration);
            formData.append('order', order);
            formData.append('documentId', id)
       
                const response = await axiosInstance({
                method: 'PATCH',
                url: `${baseURL}/api/tutorials/${id}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    
    }

    const deleteTutorials = async(id) => {
        try {
            const response = await axiosInstance({
                method: 'DELETE',
                url: `${baseURL}/api/tutorials?documentId=${id}`
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server'
                    }
                };
            }
        }
    
    }

    return {
        getTutorials,
        addTutorials,
        editTutorials,
        deleteTutorials
    };
}

import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/organisms/Header';
import Login from './components/organisms/Login';
import Dashboard from './components/pages/Tablero';
import Store from './components/pages/Store';
import Sales from './components/pages/Sales';
import Users from './components/pages/Users';
import Subscriptions from './components/pages/Subscriptions';
import Coupons from './components/pages/Coupons';
import { TutorialTable } from './components/organisms/TutorialTable';
import { useAuth } from './auth/AuthContext';
import { Catalogos } from './components/pages/Catalogos';
import { CatalogProvider } from './contexts/catalogContext';
import { SaleProvider } from './contexts/saleContext';
import { SubscriptionProvider } from './contexts/subscriptionContext';
import { ProductProvider } from './contexts/productContext';
import { TutorialProvider } from './contexts/tutorialsContext';
import { SubscriptionPriceProvider } from './contexts/subscriptionPriceContext';
import { CouponProvider } from './contexts/couponContext';
import RequireAuth from './auth/RequireAuth';

function App() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  console.log('Environment:  ', backendUrl);

  const customTheme = extendTheme({
    styles: {
      global: {
        'html, body': {
          overflow: 'auto',
        },
      },
    },
  });

  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <ChakraProvider theme={customTheme}>
        <CatalogProvider>
          <ProductProvider>
            <CouponProvider>
              <SubscriptionProvider>
                <SubscriptionPriceProvider>
                  <SaleProvider>
                    <TutorialProvider>
                      {isAuthenticated && <Header />}
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <RequireAuth>
                              <Dashboard />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/tablero"
                          element={
                            <RequireAuth>
                              <Dashboard />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/tienda"
                          element={
                            <RequireAuth>
                              <Store />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/Usuarios"
                          element={
                            <RequireAuth>
                              <Users />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/catalogos/:catalogo"
                          element={
                            <RequireAuth>
                              <Catalogos />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/tutoriales"
                          element={
                            <RequireAuth>
                              <TutorialTable />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/ventas"
                          element={
                            <RequireAuth>
                              <Sales />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/suscripciones"
                          element={
                            <RequireAuth>
                              <Subscriptions />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="/cupones"
                          element={
                            <RequireAuth>
                              <Coupons />
                            </RequireAuth>
                          }
                        />
                        <Route path="/login" element={<Login />} />
                      </Routes>
                    </TutorialProvider>
                  </SaleProvider>
                </SubscriptionPriceProvider>
              </SubscriptionProvider>
            </CouponProvider>
          </ProductProvider>
        </CatalogProvider>
      </ChakraProvider>
    </Router>
  );
}

export default App;

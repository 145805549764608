import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
} from "@chakra-ui/react";
import { useSubscriptionPrice } from '../../contexts/subscriptionPriceContext';

const CreateSubscriptionPriceModal = ({ isOpen, onClose }) => {
    const { createSubscriptionPrice } = useSubscriptionPrice();
    const [formData, setFormData] = useState({
        countryCode: "",
        countryName: "",
        price: "",
        currency: "",
    });
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;

        const newValue = (name === "countryCode" || name === "currency")
            ? value.toUpperCase()
            : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleCreate = async () => {
        const { countryCode, countryName, price, currency } = formData;

        if (!countryCode || countryCode.length !== 3) {
            toast({
                title: "Error",
                description: "El código de país debe tener 3 caracteres.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (!currency || currency.length !== 3) {
            toast({
                title: "Error",
                description: "La moneda debe tener 3 caracteres.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (!countryName || !price) {
            toast({
                title: "Error",
                description: "Todos los campos son obligatorios.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        try {
            const newSubscriptionPrice = {
                price: formData.price,
                countryCode: formData.countryCode,
                countryName: formData.countryName,
                currency: formData.currency
            };

            const success = await createSubscriptionPrice(newSubscriptionPrice);

            if (success === false) {
                toast({
                    title: 'Error al crear',
                    description: 'Hubo un problema al crear el precio de la suscripción.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Cupón creado',
                    description: 'El precio de la suscripción ha sido creado exitosamente.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            onClose();
            setFormData({
                countryCode: "",
                countryName: "",
                price: "",
                currency: ""
            });
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Crear Precio de Suscripción</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Código de País (ISO 3 letras)</FormLabel>
                        <Input
                            name="countryCode"
                            value={formData.countryCode}
                            onChange={handleChange}
                            placeholder="Ej: MEX"
                            maxLength={3}
                        />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Moneda (ISO 3 letras)</FormLabel>
                        <Input
                            name="currency"
                            value={formData.currency}
                            onChange={handleChange}
                            placeholder="Ej: MXN"
                            maxLength={3}
                        />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Nombre del País</FormLabel>
                        <Input
                            name="countryName"
                            value={formData.countryName}
                            onChange={handleChange}
                            placeholder="Ej: Mexico"
                        />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Precio</FormLabel>
                        <Input
                            name="price"
                            type="number"
                            value={formData.price}
                            onChange={handleChange}
                            placeholder="Ej: 100.00"
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        onClick={handleCreate}
                        isLoading={loading}
                        ml={3}
                    >
                        Crear
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateSubscriptionPriceModal;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Text, Flex, IconButton } from '@chakra-ui/react';
import { CatalogTable } from '../organisms/CatalogTable';
import { useCatalog } from '../../contexts/catalogContext';
import { FaArrowDown } from 'react-icons/fa';

const catalogDictionary = {
  insurance: "Seguros",
  promissory_notes: "Pagarés",
  stock: "Acciones",
  banks: "Bancos",
  proceedings: "Actas",
  credits: "Créditos",
  "Real State": "Bienes Raíces",
  "Car Or Jewelry": "Auto o Joya",
  Country: "Países"
};

export const Catalogos = () => {
  const { getCatalog, catalog, updateCatalog } = useCatalog();
  const { catalogo } = useParams();
  const [loading, setLoading] = useState(true);
  const fetchCatalogoRef = useRef();

  const handleUpdateCatalog = async (updatedElements, catalogName) => {
    setLoading(true);
    await updateCatalog(catalogName, updatedElements);
    setLoading(false);
  };

  const fetchCatalogo = async () => {
    setLoading(true);
    await getCatalog();
    setLoading(false);
  };

  fetchCatalogoRef.current = fetchCatalogo;

  useEffect(() => {
    fetchCatalogoRef.current();
  }, [catalogo]);

  const catalogoTraducido = catalogDictionary[catalogo] || catalogo;

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : catalog && Array.isArray(catalog[catalogo]) ? (
        <>
          <CatalogTable
            catalogName={catalogoTraducido}
            elements={catalog[catalogo]}
            updateCatalog={handleUpdateCatalog}
          />
          <IconButton
            icon={<FaArrowDown />}
            onClick={scrollToBottom}
            position="fixed"
            bottom="20px"
            right="20px"
            colorScheme='blue'
            variant='solid'
            aria-label="Scroll to bottom"
            size="lg"
          />
        </>
      ) : (
        <Flex justify="center" align="center" height="100vh">
          <Text>No se encontró el catálogo o el catálogo está vacío.</Text>
        </Flex>
      )}
    </>
  );
};

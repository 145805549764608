//Login.jsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import baseURL from "../../config"

import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    if (isAuthenticated) {
      navigate('/Usuarios');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async event => {
    event.preventDefault();

    try {
      const response = await axios.post(
        baseURL + '/api/users/login',
        {
          username,
          password,
        }
      );

      const { token } = response.data.data;
      login(token);
      navigate('/Usuarios');
    } catch (error) {
      if (error.response) {
        console.error('Error response', error.response);
      } else if (error.request) {
        console.error('Error request', error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };

  return (
    <Container maxW="7xl" p={{ base: 5, md: 10 }}>
      <Center>
        <Stack spacing={4}>
          <Stack align="center">
            <Heading fontSize="2xl">Entrar</Heading>
          </Stack>
          <VStack
            as="form"
            onSubmit={handleLogin}
            boxSize={{ base: 'xs', sm: 'sm', md: 'md' }}
            h="max-content !important"
            bg={useColorModeValue('white', 'gray.700')}
            rounded="lg"
            boxShadow="lg"
            p={{ base: 5, sm: 10 }}
            spacing={8}
          >
            <VStack spacing={4} w="100%">
              <FormControl id="username">
                <FormLabel>Usuario</FormLabel>
                <Input
                  rounded="md"
                  type="text"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <InputGroup size="md">
                  <Input
                    rounded="md"
                    type={show ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button onClick={handleClick}>
                      {show ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </VStack>
            <VStack w="100%">
              <Stack direction="row" justifyContent="flex-end" w="100%">
                <Link color={'blue.500'} fontSize={{ base: 'md', sm: 'md' }}>
                  Recuperar Contraseña
                </Link>
              </Stack>
              <Button
                type="submit"
                bg="green.300"
                color="white"
                _hover={{
                  bg: 'green.500',
                }}
                rounded="md"
                w="100%"
              >
                Entrar
              </Button>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Container>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { Button, Collapse, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useCatalog } from '../../contexts/catalogContext';

// Define the translation dictionary
const catalogDictionary = {
  insurance: "Seguros",
  promissory_notes: "Pagarés",
  stock: "Acciones",
  banks: "Bancos",
  proceedings: "Actas",
  credits: "Créditos",
  "Real State": "Bienes Raíces",
  "Car Or Jewelry": "Auto o Joya",
  Country: "Países"
};

const transformResponse = (response) => {
  if (!response || typeof response !== 'object') {
    return [];
  }
  return Object.keys(response).slice(1); // Excluye el primer elemento "_id"
};

export const DesplegableMobile = ({ handleNavigation }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { getCatalog, catalog } = useCatalog();
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchCatalogo = async () => {
      await getCatalog();
      setDataFetched(true);
    };

    if (!dataFetched) {
      fetchCatalogo();
    }
  }, [getCatalog, dataFetched]);

  const catalogCategories = transformResponse(catalog);

  return (
    <>
      <Button justifyContent="space-between" variant="tertiary" size="lg" onClick={onToggle}>
        <Text as="span">Cátalogos</Text>
        <ChevronDownIcon />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="4">
          {catalogCategories.map((category) => (
            <Button
              onClick={() => handleNavigation(`/catalogos/${category}`)}
              key={category}
              variant="tertiary"
              justifyContent="start"
              size="lg"
            >
              {catalogDictionary[category] || category.charAt(0).toUpperCase() + category.slice(1).replace('_', ' ')}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </>
  );
};

import React, { createContext, useContext, useState } from 'react';
import useCatalogService from '../services/catalogService';

const CatalogContext = createContext(null);

export const CatalogProvider = ({ children }) => {
  const [catalog, setCatalog] = useState([]);
  const catalogService = useCatalogService();

  const getCatalog = async () => {
    try {
      const res = await catalogService.getCatalog();
      if (res.status === 'success') {
        setCatalog(res.data);
      }
    } catch (error) {
      console.log('Hubo un error al intentar obtener el catalogo: ', error);
    }
  };

  const updateCatalog = async (catalog, data) => {
    try {
      await catalogService.updateCatalog(catalog, data);
      await getCatalog()
    } catch (error) {
      console.log('Hubo un error al actualizar el catalogo: ', error);
    }
  };

  return (
    <CatalogContext.Provider value={{ getCatalog, catalog, updateCatalog }}>
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalog = () => useContext(CatalogContext);

export default CatalogProvider;

import React from 'react';
import {
  Tr,
  Td,
  IconButton
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

export const CatalogTableRow = ({ element, updateElement, deleteElement, setEditingElement, onOpen }) => {
  const handleEdit = () => {
    setEditingElement(element);
    onOpen();
  };

  const handleDelete = () => {
    deleteElement(element);
  };

  return (
    <Tr>
      <Td fontSize={"18px"}>{element}</Td>
      <Td textAlign={'right'}>
        <IconButton
          width={"50px"}
          height={"50px"}
          fontSize={"22px"}
          aria-label="Edit"
          icon={<EditIcon />}
          onClick={handleEdit}
        />
        <IconButton
          width={"50px"}
          height={"50px"}
          fontSize={"22px"}
          aria-label="Delete"
          icon={<DeleteIcon />}
          onClick={handleDelete}
          ml={2}
        />
      </Td>
    </Tr>
  );
};

import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    IconButton,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    FormControl,
    FormLabel,
    VStack,
    Switch,
    HStack,
    Text,
    Alert,
    Spinner,
    Flex,
    AlertIcon
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useProduct } from '../../contexts/productContext';

const numericRegex = /^[0-9]+(\.[0-9]+)?$/;

const Store = () => {
    const { getProducts, product, addProduct, editProduct, deleteProduct } = useProduct();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [confirmDeleteProduct, setConfirmDeleteProduct] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const [formState, setFormState] = useState({
        title: '',
        description: '',
        image: null,
        price: '',
        cost: '',
        dimensions: { h: '', l: '', w: '' },
        weight: '',
        profit: '',
        minPrice: '',
        qty: '',
        sku: '',
        isSubscription: 'false',
    });
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState('');

    const fetchProducts = async () => {
        setLoading(true)
        await getProducts();
        setLoaded(true);
        setLoading(false)
    };

    useEffect(() => {
        if (!loaded) {
            fetchProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, getProducts]);

    const validateForm = () => {
        setError('');

        if (Object.values(formState).some(value => !value)) {
            setError('Por favor, rellene todos los campos obligatorios');
            return false;
        }

        const numericFields = ['price', 'cost', 'dimensions.h', 'dimensions.l', 'dimensions.w', 'profit', 'minPrice', 'weight'];
        for (const field of numericFields) {
            const value = field.includes('.') ? formState.dimensions[field.split('.')[1]] : formState[field];

            if (value && !numericRegex.test(value)) {
                setError(`Valor no válido en ${field}. Sólo se permiten valores numéricos`);
                return false;
            }
        }

        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('dimensions.')) {
            const dimKey = name.split('.')[1];
            setFormState({
                ...formState,
                dimensions: {
                    ...formState.dimensions,
                    [dimKey]: value,
                },
            });
        } else {
            setFormState({
                ...formState,
                [name]: value.replace(',', '.'),
            });
        }
    };

    const handleFileChange = (e) => {
        setFormState({
            ...formState,
            image: e.target.files[0],
        });
    };

    const handleEdit = (product) => {
        setError('')
        setSelectedProduct(product);
        setFormState({
            title: product.title,
            description: product.description,
            image: product.image,
            price: product.price,
            cost: product.cost,
            dimensions: product.dimensions,
            weight: product.weight,
            profit: product.profit,
            minPrice: product.minPrice,
            qty: product.qty,
            sku: product.sku,
            isSubscription: product.isSubscription,
        });
        onOpen();
    };

    const handleAddProduct = () => {
        setError('')
        setSelectedProduct(null);
        setFormState({
            title: '',
            description: '',
            image: null,
            price: '',
            cost: '',
            dimensions: { h: '', l: '', w: '' },
            weight: '',
            profit: '',
            minPrice: '',
            qty: '',
            sku: '',
            isSubscription: 'false',
        });
        onOpen();
    };

    const handleDelete = (sku) => {
        setConfirmDeleteProduct(sku);
        onConfirmOpen();
    };

    const confirmDeleteProductHandler = async () => {
        if (confirmDeleteProduct) {
            setLoading(true)
            await deleteProduct(confirmDeleteProduct);
            setLoaded(false);
            setConfirmDeleteProduct(null);
            onConfirmClose();
            setLoading(false)
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        const isSkuDuplicate = product.some(
            (prod) => prod.sku === formState.sku && (!selectedProduct || prod.sku !== selectedProduct.sku)
        );

        if (isSkuDuplicate) {
            setError('El SKU ya existe, por favor ingrese un SKU diferente');
            return;
        }

        const formData = new FormData();

        Object.keys(formState).forEach((key) => {
            if (key === 'dimensions') {
                formData.append('dimensions[h]', formState.dimensions.h);
                formData.append('dimensions[l]', formState.dimensions.l);
                formData.append('dimensions[w]', formState.dimensions.w);
            } else {
                if (key === 'image' && formState.image) {
                    formData.append('image', formState.image);
                } else {
                    formData.append(key, formState[key]);
                }
            }
        });

        try {
            if (selectedProduct) {
                setLoading(true)
                await editProduct(selectedProduct.sku, formData);
                setLoading(false)
            } else {
                setLoading(true)
                await addProduct(formData);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error details:', error.response?.data || error.message);
        }

        onClose();
        setLoaded(false);
        setFormState({
            title: '',
            description: '',
            image: null,
            price: '',
            cost: '',
            dimensions: { h: '', l: '', w: '' },
            weight: '',
            profit: '',
            minPrice: '',
            qty: '',
            sku: '',
            isSubscription: 'false',
        });
    };

    return (
        <Box margin={5}>
            {loading ? (
                <Flex justify="center" align="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
            ) : (
                <>
                    <HStack justifyContent="space-between" mx={5} mb={4}>
                        <Text
                            fontSize={{ base: "md", md: "lg", lg: "25px" }}
                            fontWeight="bold"
                        >Productos</Text>
                        <Button
                            colorScheme="green"
                            onClick={handleAddProduct}
                            fontSize={{ base: "15px", md: "12px", lg: "16px" }}
                        >
                            <Text
                                display={{ base: "none", md: "inline" }}
                            >
                                Agregar Producto
                            </Text>
                            <Text
                                display={{ base: "inline", md: "none" }}
                            >
                                Agregar
                            </Text>
                        </Button>
                    </HStack>
                    <Table mt={4}>
                        <Thead>
                            <Tr>
                                <Th>Producto</Th>
                                <Th>SKU</Th>
                                <Th>Precio</Th>
                                <Th>Costo</Th>
                                <Th>Ganancia</Th>
                                <Th>Cantidad</Th>
                                <Th>Imagen</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {product?.map((prod) => (
                                <Tr key={prod._id}>
                                    <Td>{prod.title}</Td>
                                    <Td>{prod.sku}</Td>
                                    <Td>{prod.price}</Td>
                                    <Td>{prod.cost}</Td>
                                    <Td>{prod.profit}</Td>
                                    <Td>{prod.qty}</Td>
                                    <Td>
                                        <Image src={prod.image} alt={prod.title} boxSize="50px" />
                                    </Td>
                                    <Td>
                                        <IconButton
                                            icon={<EditIcon />}
                                            onClick={() => handleEdit(prod)}
                                            mr={2}
                                            my={1}
                                        />
                                        <IconButton
                                            my={1}
                                            icon={<DeleteIcon />}
                                            onClick={() => handleDelete(prod.sku)}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{selectedProduct ? 'Editar Producto' : 'Agregar Producto'}</ModalHeader>
                            <ModalCloseButton />
                            {error && (
                                <Alert status="error" mb={4}>
                                    <AlertIcon />
                                    {error}
                                </Alert>
                            )}
                            <ModalBody>
                                <VStack spacing={4}>
                                    <FormControl isRequired>
                                        <FormLabel>Titulo</FormLabel>
                                        <Input
                                            name="title"
                                            value={formState.title}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Descripción</FormLabel>
                                        <Input
                                            name="description"
                                            value={formState.description}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Imagen</FormLabel>
                                        <Input
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Precio</FormLabel>
                                        <Input
                                            name="price"
                                            type="number"
                                            value={formState.price}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Costo</FormLabel>
                                        <Input
                                            name="cost"
                                            type="number"
                                            value={formState.cost}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <HStack spacing={4}>
                                        <FormControl isRequired>
                                            <FormLabel>Alto</FormLabel>
                                            <Input
                                                name="dimensions.h"
                                                type="number"
                                                value={formState.dimensions.h}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Largo</FormLabel>
                                            <Input
                                                name="dimensions.l"
                                                type="number"
                                                value={formState.dimensions.l}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Ancho</FormLabel>
                                            <Input
                                                name="dimensions.w"
                                                type="number"
                                                value={formState.dimensions.w}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </HStack>
                                    <FormControl isRequired>
                                        <FormLabel>Peso</FormLabel>
                                        <Input
                                            name="weight"
                                            type="number"
                                            value={formState.weight}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Ganancia</FormLabel>
                                        <Input
                                            name="profit"
                                            type="number"
                                            value={formState.profit}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Precio Mínimo</FormLabel>
                                        <Input
                                            name="minPrice"
                                            type="number"
                                            value={formState.minPrice}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Cantidad</FormLabel>
                                        <Input
                                            name="qty"
                                            type="number"
                                            value={formState.qty}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>SKU</FormLabel>
                                        <Input
                                            name="sku"
                                            value={formState.sku}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Suscripción</FormLabel>
                                        <Switch
                                            name="isSubscription"
                                            isChecked={formState.isSubscription === 'true'}
                                            onChange={(e) => setFormState({ ...formState, isSubscription: e.target.checked ? 'true' : 'false' })}
                                        />
                                    </FormControl>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" onClick={handleSubmit}>
                                    {selectedProduct ? 'Actualizar Producto' : 'Agregar Producto'}
                                </Button>
                                <Button colorScheme="gray" onClick={onClose} ml={3}>
                                    Cancelar
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirmar Eliminación</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text>¿Estás seguro de que deseas eliminar este producto?</Text>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" onClick={confirmDeleteProductHandler}>
                                    Eliminar
                                </Button>
                                <Button colorScheme="gray" onClick={onConfirmClose} ml={3}>
                                    Cancelar
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Box>
    );
};

export default Store;

import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Box,
  Container,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import useAxiosConfig from '../../utils/axiosConfig';

const UserTable = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('black', 'white');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const { axiosInstance } = useAxiosConfig();

  const fetchUsersRef = useRef(async () => {
    try {
      const response = await axiosInstance.get('/api/users');
      setUsers(response.data.data.users);
      setFilteredUsers(response?.data?.data?.users);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  });

  useEffect(() => {
    fetchUsersRef.current();
  }, []);

  useEffect(() => {
    const filtered = users.filter(
      user =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.phone.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  return (
    <Box bg={bgColor} color={color} minH="100vh" p={5}>
      <Container maxW="container.xl">
        <HStack justifyContent="space-between" mb={5}>
          <HStack>
            <Text fontSize="2xl" fontWeight="bold">
              Total de Usuarios
            </Text>
            <Text fontSize="xl" ml={5}>
              {filteredUsers.length} Usuarios
            </Text>
          </HStack>
          <HStack>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Buscar..."
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </InputGroup>
          </HStack>
        </HStack>
        <Box overflowY="auto" maxH="70vh">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Email</Th>
                <Th>Teléfono</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredUsers.map(user => (
                <Tr key={user._id}>
                  <Td>
                    <HStack spacing={3}>
                      <Avatar boxSize="10" name={user.name} />
                      <Text>
                        {user.name} {user.lastName}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>{user.phone}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Box>
  );
};

export default UserTable;

import React, { useEffect, useState, useRef } from 'react';
import { useTutorial } from '../../contexts/tutorialsContext';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  IconButton,
  useBreakpointValue,
  InputLeftAddon,
  InputGroup,
  Spinner, 
  Flex,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

export const TutorialTable = () => {
  const [loading, setLoading] = useState(true);

  const { getTutorials, tutorial, addTutoriales,editTutoriales, deleteTutoriales } =
    useTutorial();
  const [editingTutorial, setEditingTutorial] = useState(null);
  const [newTutorial, setNewTutorial] = useState({
    file: null,
    title: '',
    description: '',
    duration: '',
    order: '',
  });

  const getTutorialsRef = useRef(getTutorials);

  const fetchTutorials = async () => {
    setLoading(true);
    await getTutorialsRef.current();
    setLoading(false);
  };

  useEffect(() => {
    fetchTutorials();
  }, []);

  const handleFileChange = (e, tutorial) => {
    const file = e.target.files[0];
    if (tutorial) {
      setEditingTutorial({ ...tutorial, file });
    } else {
      setNewTutorial({ ...newTutorial, file });
    }
  };

  const handleInputChange = (e, tutorial) => {
    const { name, value } = e.target;
    if (tutorial) {
      setEditingTutorial({ ...tutorial, [name]: value });
    } else {
      setNewTutorial({ ...newTutorial, [name]: value });
    }
  };

  const handleEditClick = tutorial => {
    setEditingTutorial(tutorial);
  };

  const handleDeleteClick = async tutorial => {
    setLoading(true);
    await deleteTutoriales(tutorial._id);
    await fetchTutorials();
    setLoading(false);
  };

  const handleSaveClick = async () => {

    setLoading(true);
    await editTutoriales(
      editingTutorial.file,
      editingTutorial.title,
      editingTutorial.description,
      editingTutorial.duration,
      editingTutorial.order,
      editingTutorial._id
    );
    setEditingTutorial(null);
    await fetchTutorials();
    setLoading(false);
  };

  const handleAddClick = async () => {
    setLoading(true);
    await addTutoriales(
      newTutorial.file,
      newTutorial.title,
      newTutorial.description,
      newTutorial.duration,
      newTutorial.order
    );
    setNewTutorial({
      file: null,
      title: '',
      description: '',
      duration: '',
      order: '',
    });
    await fetchTutorials();
    setLoading(false);
  };

  const tableSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box p={5}>
          <VStack spacing={5} align="stretch">
            <Box overflowX="auto">
              <Table variant="simple" size={tableSize}>
                <Thead>
                  <Tr>
                    <Th>Título</Th>
                    <Th>Descripción</Th>
                    <Th>Duración</Th>
                    <Th>Orden</Th>
                    <Th>Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(tutorial) &&
                    tutorial.map((tut, index) => (
                      <Tr key={tut.id || index}>
                        <Td>{tut.title}</Td>
                        <Td>{tut.description}</Td>
                        <Td>{tut.duration}</Td>
                        <Td>{tut.order}</Td>
                        <Td>
                          <IconButton
                            width={'50px'}
                            height={'50px'}
                            fontSize={'22px'}
                            aria-label="Edit"
                            icon={<EditIcon />}
                            onClick={() => handleEditClick(tut)}
                            mr={2}
                            colorScheme="blue"
                          />
                          <IconButton
                            width={'50px'}
                            height={'50px'}
                            fontSize={'22px'}
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() => handleDeleteClick(tut)}
                            colorScheme="red"
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            {editingTutorial && (
              <Box borderWidth="1px" borderRadius="lg" p={5}>
                <FormControl>
                  <FormLabel>Seleccionar nuevo archivo</FormLabel>
                  <Input
                    type="file"
                    onChange={e => handleFileChange(e, editingTutorial)}
                  />
                   <InputGroup size="lg">
                    <InputLeftAddon children="Archivo actual" />
                    <Input
                      type="text"
                      name="title"
                      value={editingTutorial.filename}
                      isDisabled={true}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Título" />
                    <Input
                      type="text"
                      name="title"
                      value={editingTutorial.title}
                      onChange={e => handleInputChange(e, editingTutorial)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Descripción" />
                    <Input
                      type="text"
                      name="description"
                      value={editingTutorial.description}
                      onChange={e => handleInputChange(e, editingTutorial)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Duración" />
                    <Input
                      type="text"
                      name="duration"
                      value={editingTutorial.duration}
                      onChange={e => handleInputChange(e, editingTutorial)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Orden" />
                    <Input
                      type="text"
                      name="order"
                      value={editingTutorial.order}
                      onChange={e => handleInputChange(e, editingTutorial)}
                    />
                  </InputGroup>
                </FormControl>
                <Button
                  mt={4}
                  onClick={handleSaveClick}
                  colorScheme="blue"
                  mr={2}
                >
                  Guardar
                </Button>
                <Button
                  mt={4}
                  onClick={() => setEditingTutorial(null)}
                  colorScheme="red"
                >
                  Cancelar
                </Button>
              </Box>
            )}
            {!editingTutorial && (
              <Box borderWidth="1px" borderRadius="lg" p={5}>
                <FormControl>
                  <FormLabel>Archivo</FormLabel>
                  <Input
                    type="file"
                    onChange={e => handleFileChange(e, null)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Título" />
                    <Input
                      type="text"
                      name="title"
                      value={newTutorial.title}
                      onChange={e => handleInputChange(e, null)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Descripción" />
                    <Input
                      type="text"
                      name="description"
                      value={newTutorial.description}
                      onChange={e => handleInputChange(e, null)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Duración" />
                    <Input
                      type="text"
                      name="duration"
                      value={newTutorial.duration}
                      onChange={e => handleInputChange(e, null)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size="lg">
                    <InputLeftAddon children="Orden" />
                    <Input
                      type="text"
                      name="order"
                      value={newTutorial.order}
                      onChange={e => handleInputChange(e, null)}
                    />
                  </InputGroup>
                </FormControl>
                <Button mt={4} onClick={handleAddClick} colorScheme="green">
                  Añadir
                </Button>
              </Box>
            )}
          </VStack>
        </Box>
      )}
    </>
  );
};

export default TutorialTable;

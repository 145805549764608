import React, { createContext, useContext, useState } from 'react';
import useSubscriptionService from '../services/subscriptionService';

const SubscriptionContext = createContext(null);

export const SubscriptionProvider = ({ children }) => {
  const [limitSubscriptions, setLimitSubscriptions] = useState([]);
  const [subscriptionsQuantity, setSubscriptionsQuantity] = useState(0);
  const SubscriptionService = useSubscriptionService();

  const getLimitSubscriptions = async (limit, offset, status) => {
    try {
      const limitSubscriptions = await SubscriptionService.getSubscriptions(
        limit,
        offset,
        status
      );
      setLimitSubscriptions(limitSubscriptions);
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener la pagina de suscripciones: ',
        error
      );
    }
  };

  const getSubscription = async subscriptionId => {
    try {
      const subscription = await SubscriptionService.getSubscription(
        subscriptionId
      );
      return subscription;
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener la suscripcion: ',
        error
      );
    }
  };

  const getSubscriptionsQuantity = async status => {
    try {
      const quantity = await SubscriptionService.getSubscriptionsQuantity(
        status
      );
      setSubscriptionsQuantity(quantity);
    } catch (error) {
      console.error(
        'Hubo un error al intentar obtener la pagina de suscripciones: ',
        error
      );
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        getLimitSubscriptions,
        getSubscriptionsQuantity,
        getSubscription,
        subscriptionsQuantity,
        setSubscriptionsQuantity,
        limitSubscriptions,
        setLimitSubscriptions,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);

export default SubscriptionProvider;

import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useSaleService() {
  const { axiosInstance } = useAxiosConfig();

  const getSales = async (limit, offset) => {
    try {
      const sales = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/personal/payment/all`,
        params: {
          limit,
          offset,
        },
      });
      return sales.data.data.payments;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const getSale = async paymentId => {
    try {
      const sale = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/personal/payment/single-payment/${paymentId}`,
      });
      return sale.data.data.payment;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  const getSalesQuantity = async () => {
    try {
      const sales = await axiosInstance({
        method: 'GET',
        url: `${baseURL}/api/personal/payment/quantity`,
      });

      return sales.data.data.payments_quantity;
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response.data.error,
        };
      } else {
        return {
          success: false,
          error: {
            code: 'NETWORK_ERROR',
            details: 'No response from server',
          },
        };
      }
    }
  };

  return {
    getSalesQuantity,
    getSales,
    getSale,
  };
}

import React, { useState, useEffect } from 'react';
import { useSubscription } from '../../contexts/subscriptionContext';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  Text,
  Flex,
  Box,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  Heading,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import CreateSubscriptionPriceModal from '../organisms/CreateSubscriptionPriceModal';

const Subscriptions = () => {
  const {
    getLimitSubscriptions,
    getSubscription,
    getSubscriptionsQuantity,
    limitSubscriptions,
    subscriptionsQuantity,
    setLimitSubscriptions,
    setSubscriptionsQuantity,
  } = useSubscription();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState('authorized');
  const [isSearching, setIsSearching] = useState(false);

  const {
    isOpen: isSetSubscriptionOpen,
    onOpen: onSetSubscriptionOpen,
    onClose: onSetSubscriptionClose,
  } = useDisclosure();

  const itemsPerPage = 10;

  const fetchSubscriptions = async page => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage;
    await getLimitSubscriptions(itemsPerPage, offset, status);
    await getSubscriptionsQuantity(status);
    setLoading(false);
  };

  useEffect(() => {
    if (!isSearching) {
      fetchSubscriptions(currentPage);
    }
    setIsSearching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, status]);

  const handleSearch = async () => {
    setLoading(true);
    setCurrentPage(1);
    setIsSearching(true);
    const subscription = await getSubscription(searchTerm);
    setLimitSubscriptions([subscription]);
    setSubscriptionsQuantity(1);
    setIsSearching(false);
    setLoading(false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchSubscriptions(1);
  };

  const handleOpenDetail = subscription => {
    setSelectedSubscription(subscription);
    onOpen();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && searchTerm !== '') {
      handleSearch();
    }
  };

  const totalPages = Math.ceil(subscriptionsQuantity / itemsPerPage);

  const handleOpenSet = async () => {
    onSetSubscriptionOpen();
  };

  return (
    <Box m={8}>
      <Flex
        px="16px"
        justifyContent="space-between"
        alignItems="center"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Heading as="h2" size="lg" textAlign={'center'}>
          Suscripciones
        </Heading>
        <Flex
          my={4}
          alignItems="center"
          direction={{ base: 'column', md: 'row' }}
          w={{ base: '100%', md: 'auto' }}
        >
          <Input
            placeholder="Buscar por MP ID"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            width={{ base: '100%', md: '300px' }}
          />
          <Flex>
            {searchTerm && (
              <IconButton
                icon={<CloseIcon />}
                onClick={handleClearSearch}
                ml={{ base: 0, md: 2 }}
                mt={{ base: 2, md: 0 }}
                aria-label="Limpiar búsqueda"
              />
            )}
            <Button
              onClick={handleSearch}
              colorScheme="teal"
              ml={2}
              mt={{ base: 2, md: 0 }}
              isDisabled={!searchTerm.trim()}
            >
              Buscar
            </Button>
            <Select
              value={status}
              mt={{ base: 2, md: 0 }}
              onChange={e => { setStatus(e.target.value); setCurrentPage(1); }}
              ml={4}
              width="150px"
            >
              <option value="authorized">Authorized</option>
              <option value="paused">Paused</option>
              <option value="cancelled">Cancelled</option>
            </Select>
          </Flex>
          <Button colorScheme="green" ml={4} mt={{ base: 2, md: 0 }} onClick={handleOpenSet}>
            Definir Suscripción
          </Button>
        </Flex>
      </Flex>
      {loading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Suscripción ID (MP ID)</Th>
                <Th>Email del Pagador</Th>
                <Th>Monto</Th>
                <Th>Estado</Th>
                <Th>Fecha de Creación</Th>
                <Th>Detalle</Th>
              </Tr>
            </Thead>
            <Tbody>
              {limitSubscriptions[0] ? (
                limitSubscriptions.map(subscription => (
                  <Tr key={subscription._id}>
                    <Td>{subscription.mpId}</Td>
                    <Td>{subscription.payer_email || 'N/A'}</Td>
                    <Td>{'$' + subscription.planDetails.transaction_amount}</Td>
                    <Td>
                      <Text
                        color={
                          subscription.status === 'authorized'
                            ? 'green.500'
                            : subscription.status === 'paused'
                              ? 'yellow.500'
                              : subscription.status === 'cancelled'
                                ? 'red.500'
                                : 'gray.500'
                        }
                        textTransform={'uppercase'}
                        fontSize={'13px'}
                      >
                        {subscription.status}
                      </Text>
                    </Td>
                    <Td>
                      {new Date(subscription.date_created).toLocaleDateString()}
                    </Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        onClick={() => handleOpenDetail(subscription)}
                      >
                        Ver Detalle
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No se han encontrado suscripciones con ese MP ID
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Flex justify="space-between" mt={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
            />
            <Text>
              {currentPage} de {totalPages}
            </Text>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() =>
                setCurrentPage(prev => Math.min(prev + 1, totalPages))
              }
              isDisabled={currentPage === totalPages}
            />
          </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Detalle de la Suscripción</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {selectedSubscription && (
                  <>
                    <Text>
                      <strong>MP ID:</strong> {selectedSubscription.mpId}
                    </Text>
                    <Text>
                      <strong>Email:</strong>{' '}
                      {selectedSubscription.payer_email || 'N/A'}
                    </Text>
                    <Text textTransform={'uppercase'}>
                      <strong style={{ textTransform: 'capitalize' }}>
                        Método de Pago:
                      </strong>{' '}
                      {selectedSubscription.payment_method_id || 'N/A'}
                    </Text>
                    <Text textTransform={'uppercase'}>
                      <strong style={{ textTransform: 'capitalize' }}>
                        Estado:
                      </strong>{' '}
                      {selectedSubscription.status}
                    </Text>
                    <Text>
                      <strong>Fecha de Creación:</strong>{' '}
                      {new Date(
                        selectedSubscription.date_created
                      ).toLocaleDateString()}
                    </Text>
                    {selectedSubscription.bills && selectedSubscription.bills.length > 0 ? (
                      <Box mt={4}>
                        <Text fontWeight="bold">Últimas 10 Facturas:</Text>
                        {selectedSubscription.bills
                          .slice(0, 10)
                          .map((bill, index) => (
                            <Box
                              key={index}
                              p={2}
                              border="1px solid #ccc"
                              mt={2}
                            >
                              <Text>
                                <strong>ID Transacción:</strong> {bill.mpId}
                              </Text>
                              <Text>
                                <strong>Monto:</strong> ${bill.transactionAmount}
                              </Text>
                              <Text textTransform={'uppercase'}>
                                <strong
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  Estado:
                                </strong>{' '}
                                {bill.payment.status}
                              </Text>
                              <Text>
                                <strong>Fecha de Débito:</strong>{' '}
                                {new Date(bill.debitDate).toLocaleDateString()}
                              </Text>
                              <Text textTransform={'uppercase'}>
                                <strong
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  N° Intentos de Cobro:
                                </strong>{' '}
                                {bill.retryAttempt}
                              </Text>
                            </Box>
                          ))}
                      </Box>
                    ) : (
                      <Box mt={4}>
                        <Text>No hay facturas asociadas</Text>
                      </Box>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={onClose}>
                  Cerrar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <CreateSubscriptionPriceModal
            isOpen={isSetSubscriptionOpen}
            onClose={onSetSubscriptionClose}
            fetch={"fetch"}
          />

        </>
      )}
    </Box>
  );
};

export default Subscriptions;

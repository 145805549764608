import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Flex,
    Box,
    Spinner,
} from '@chakra-ui/react';
import { useCoupon } from '../../contexts/couponContext';

const CouponDetailModal = ({ isOpen, onClose, couponId }) => {
    const [couponDetails, setCouponDetails] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(true);
    const { getCouponCodes } = useCoupon();

    useEffect(() => {
        const fetchCouponDetails = async () => {
            if (isOpen && couponId) {
                setLoadingDetails(true);
                const details = await getCouponCodes(couponId);
                setCouponDetails(details);
                setLoadingDetails(false);
            }
        };

        fetchCouponDetails();
    }, [isOpen, couponId, getCouponCodes]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Códigos Asociados al Cupón</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loadingDetails ? (
                        <Flex justify="center" align="center">
                            <Spinner size="lg" />
                        </Flex>
                    ) : (
                        couponDetails && couponDetails.map(coupon => (
                            <Box key={coupon._id} mb={4} p={4} border="1px" borderColor="gray.200" borderRadius="md">
                                <Text>
                                    <strong>Código:</strong> {coupon.code}
                                </Text>
                                <Text>
                                    <strong>Uso restante:</strong> {coupon.remainingUses}
                                </Text>
                                <Text>
                                    <strong>ID del código:</strong> {coupon._id}
                                </Text>
                            </Box>
                        ))
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CouponDetailModal;

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCatalog } from '../../contexts/catalogContext';
import { ChevronDownIcon } from '@chakra-ui/icons';

const catalogDictionary = {
  insurance: "Seguros",
  promissory_notes: "Pagarés",
  stock: "Acciones",
  banks: "Bancos",
  proceedings: "Actas",
  credits: "Créditos",
  "Real State": "Bienes Raíces",
  "Car Or Jewelry": "Auto o Joya",
  Country: "Países"
};

export const DesplegableDesktop = () => {
  const { getCatalog, catalog } = useCatalog();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);

  const fetchCatalogoRef = useRef(async () => {
    await getCatalog();
    setDataFetched(true);
  });

  useEffect(() => {
    if (!dataFetched) {
      fetchCatalogoRef.current();
    }
  }, [dataFetched]);

  const transformResponse = response => {
    if (!response || typeof response !== 'object') {
      return [];
    }
    return Object.keys(response).slice(1); // Excluye el primer elemento "_id"
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      openDelay={0}
    >
      <PopoverTrigger>
        <Button fontSize="13px" rightIcon={<ChevronDownIcon />}>Catálogos</Button>
      </PopoverTrigger>
      <PopoverContent width="100%" p="2" >
        <Stack spacing="1">
          {transformResponse(catalog).map(category => (
            <Button
              onClick={() => navigate(`/catalogos/${category}`)}
              key={category}
              variant="tertiary"
              justifyContent="start"
            >
              {catalogDictionary[category] || category.charAt(0).toUpperCase() + category.slice(1).replace('_', ' ')}
            </Button>
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

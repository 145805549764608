import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Select,
    useToast,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { useCoupon } from '../../contexts/couponContext';

const CreateCouponModal = ({ isOpen, onClose, fetchCoupons }) => {
    const { createCoupon } = useCoupon();
    const [formData, setFormData] = useState({
        expires_at: '',
        discount: '',
        tipo_de_fundacion: 'gob',
        fundacion: '',
        via_fundacion: '',
        equals: 'no',
        qty: 1,
    });
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? e.target.checked : value,
        }));
    };

    const handleCreate = async () => {
        if (!formData.expires_at || !formData.discount || !formData.qty) {
            toast({
                title: 'Error de validación',
                description: 'Los campos expiración, descuento y cantidad son obligatorios.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const expiresDate = new Date(formData.expires_at);
        const currentDate = new Date();
        if (expiresDate <= currentDate) {
            toast({
                title: 'Error de fecha',
                description: 'La fecha de expiración debe ser mayor a la fecha actual.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (formData.discount < 1 || formData.discount > 100) {
            toast({
                title: 'Error de descuento',
                description: 'El descuento debe estar entre 1 y 100.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (formData.qty <= 0) {
            toast({
                title: 'Error de cantidad',
                description: 'La cantidad debe ser mayor a 0.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);
        try {
            const newCoupon = {
                expires_at: formData.expires_at,
                discount: formData.discount,
                tipo_de_fundacion: formData.tipo_de_fundacion,
                fundacion: formData.fundacion || undefined,
                via_fundacion: formData.via_fundacion || undefined,
                equals: formData.equals === 'si',
                qty: formData.qty,
            };

            const success = await createCoupon(newCoupon);

            if (success === false) {
                toast({
                    title: 'Error al crear',
                    description: 'Hubo un problema al crear el cupón.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Cupón creado',
                    description: 'El cupón ha sido creado exitosamente.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            onClose();
            setFormData({
                expires_at: '',
                discount: '',
                tipo_de_fundacion: 'gob',
                fundacion: '',
                via_fundacion: '',
                equals: 'no',
                qty: 1,
            });
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
            fetchCoupons();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Crear Cupón</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4} isRequired>
                        <FormLabel>Fecha de Expiración</FormLabel>
                        <Input
                            type="date"
                            name="expires_at"
                            value={formData.expires_at}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Descuento (%)</FormLabel>
                        <Input
                            type="number"
                            name="discount"
                            min={1}
                            max={100}
                            value={formData.discount}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Tipo de Fundación</FormLabel>
                        <Select
                            name="tipo_de_fundacion"
                            value={formData.tipo_de_fundacion}
                            onChange={handleChange}
                        >
                            <option value="gob">Gobierno</option>
                            <option value="empresa">Empresa</option>
                            <option value="otra">Otra</option>
                        </Select>
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Fundación</FormLabel>
                        <Input
                            type="text"
                            name="fundacion"
                            value={formData.fundacion}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Vía Fundación</FormLabel>
                        <Input
                            type="text"
                            name="via_fundacion"
                            value={formData.via_fundacion}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Cantidad de Códigos</FormLabel>
                        <Input
                            type="number"
                            name="qty"
                            min={1}
                            value={formData.qty}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>¿Códigos Iguales?</FormLabel>
                        <Select
                            name="equals"
                            value={formData.equals}
                            onChange={handleChange}
                        >
                            <option value="si">Sí</option>
                            <option value="no">No</option>
                        </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleCreate}
                        isLoading={loading}
                        ml={3}
                    >
                        Crear
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateCouponModal;

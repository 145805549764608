import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useSubscriptionService() {
    const { axiosInstance } = useAxiosConfig();

    const getSubscriptionPrice = async countryCode => {
        try {
            const subscriptions = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/vendedor/subscription/price`,
                params: {
                    countryCode
                },
            });
            return subscriptions.data.data.subscriptionPrice;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const getSubscriptionPrices = async () => {
        try {
            const subscriptions = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/vendedor/subscription/price`,
            });
            return subscriptions.data.data.subscriptionPrice;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const createSubscriptionPrice = async subscriptionPrice => {
        try {
            const response = await axiosInstance({
                method: 'POST',
                url: `${baseURL}/api/vendedor/subscription/price`,
                data: subscriptionPrice,
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const updateSubscriptionPrice = async (countryCode, updatedSubscriptionPrice) => {
        try {
            const response = await axiosInstance({
                method: 'PUT',
                url: `${baseURL}/api/vendedor/subscription/price/edit`,
                data: updatedSubscriptionPrice,
                params: {
                    countryCode
                },
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const deleteSubscriptionPrice = async countryCode => {
        try {
            const response = await axiosInstance({
                method: 'DELETE',
                url: `${baseURL}/api/vendedor/subscription/price/delete`,
                params: {
                    countryCode
                },
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    return {
        createSubscriptionPrice,
        deleteSubscriptionPrice,
        updateSubscriptionPrice,
        getSubscriptionPrices,
        getSubscriptionPrice
    };
}

import React from 'react';
import {
    Badge,
    Box,
    Heading,
    HStack,
    Icon,
    Stack,
    Text,
    SimpleGrid,
    useColorModeValue,
    Container,
} from '@chakra-ui/react';
import { FiArrowDownRight, FiArrowUpRight, FiMoreVertical } from 'react-icons/fi';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Stat = ({ label, value, delta, ...boxProps }) => {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.700')}
            borderRadius="lg"
            boxShadow="sm"
            {...boxProps}
        >
            <Box px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack>
                    <HStack justify="space-between">
                        <Text textStyle="sm" color={useColorModeValue('gray.600', 'gray.400')}>
                            {label}
                        </Text>
                        <Icon as={FiMoreVertical} boxSize="5" color={useColorModeValue('gray.600', 'gray.400')} />
                    </HStack>
                    <HStack justify="space-between">
                        <Heading size={{ base: 'sm', md: 'md' }}>{value}</Heading>
                        <Badge variant="solid" colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
                            <HStack spacing="1">
                                <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight} />
                                <Text>{delta.value}</Text>
                            </HStack>
                        </Badge>
                    </HStack>
                </Stack>
            </Box>
            {/*   <Divider />
            <Box px={{ base: '4', md: '6' }} py="4">
                <Button variant="link" colorScheme="blue" size="sm">
                    Learn more
                </Button>
            </Box> */}
        </Box>
    );
};

const Dashboard = () => {
    const stats = [
        { label: 'Número de Usuarios', value: '1,000', delta: { value: '5%', isUpwardsTrend: true } },
        { label: 'Subscripciones', value: '300', delta: { value: '3%', isUpwardsTrend: false } },
        { label: 'Tiempo por Usuario', value: '5 min', delta: { value: '10%', isUpwardsTrend: true } },
        { label: 'Ventas', value: '$5,000', delta: { value: '8%', isUpwardsTrend: true } },
        { label: 'Interacciones de Chat', value: '150', delta: { value: '2%', isUpwardsTrend: false } },
    ];

    /*     const cities = [
            { name: 'Ciudad A', users: 200 },
            { name: 'Ciudad B', users: 150 },
            { name: 'Ciudad C', users: 100 },
        ]; */

    const userChartData = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
            {
                label: 'Usuarios',
                data: [50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325],
                backgroundColor: useColorModeValue('blue', 'cyan'),
            },
        ],
    };

    const salesChartData = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
            {
                label: 'Ventas',
                data: [500, 700, 800, 900, 1000, 1200, 1400, 1500, 1600, 1800, 2000, 2200],
                backgroundColor: useColorModeValue('green', 'lime'),
            },
        ],
    };

    return (
        <Container maxW="container.xl" py={5} h="100vh" overflowY="auto">
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5} mb={5}>
                {stats.map((stat, index) => (
                    <Stat key={index} label={stat.label} value={stat.value} delta={stat.delta} />
                ))}
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
                <Box bg={useColorModeValue('gray.50', 'gray.700')} borderRadius="lg" boxShadow="sm" p={5}>
                    <Heading size="md" mb={4}>Número de Usuarios últimos 12 meses</Heading>
                    <Bar data={userChartData} />
                </Box>
                <Box bg={useColorModeValue('gray.50', 'gray.700')} borderRadius="lg" boxShadow="sm" p={5}>
                    <Heading size="md" mb={4}>Número de Ventas últimos 12 meses</Heading>
                    <Bar data={salesChartData} />
                </Box>
            </SimpleGrid>
            {/* <Box bg={useColorModeValue('gray.50', 'gray.700')} borderRadius="lg" boxShadow="sm" p={5} maxH="400px" overflowY="auto">
                <Heading size="md" mb={4}>Ciudades</Heading>
                <Box>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Ciudad</Th>
                                <Th>Usuarios</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {cities.map((city, index) => (
                                <Tr key={index}>
                                    <Td>{city.name}</Td>
                                    <Td>{city.users}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box> */}
        </Container>
    );
};

export default Dashboard;

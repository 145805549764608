import useAxiosConfig from '../utils/axiosConfig';
import baseURL from '../config';

export default function useCouponService() {
    const { axiosInstance } = useAxiosConfig();

    const getLimitCoupons = async (limit, offset, foundationType) => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/coupon`,
                params: {
                    limit,
                    offset,
                    tipo_de_fundacion: foundationType,
                },
            });
            return response.data.data.coupons;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const getCoupons = async (searchedFoundation, foundationType) => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/coupon/fundacion`,
                params: {
                    fundacion: searchedFoundation,
                    tipo_de_fundacion: foundationType,
                },
            });
            return response.data.data.coupons;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const getCouponsQuantity = async foundationType => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/coupon/all`,
                params: {
                    tipo_de_fundacion: foundationType,
                },
            });
            return response.data.data.quantity;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const getCouponCodes = async couponId => {
        try {
            const response = await axiosInstance({
                method: 'GET',
                url: `${baseURL}/api/codes`,
                params: {
                    couponId,
                },
            });
            return response.data.data.codes;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const createCoupon = async coupon => {
        try {
            const response = await axiosInstance({
                method: 'POST',
                url: `${baseURL}/api/coupon`,
                data: coupon,
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const updateCoupon = async (couponId, updatedCoupon) => {
        try {
            const response = await axiosInstance({
                method: 'PUT',
                url: `${baseURL}/api/coupon/${couponId}`,
                data: updatedCoupon,
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    const deleteCoupon = async couponId => {
        try {
            const response = await axiosInstance({
                method: 'DELETE',
                url: `${baseURL}/api/coupon/${couponId}`,
            });
            return response.data.data;
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    error: error.response.data.error,
                };
            } else {
                return {
                    success: false,
                    error: {
                        code: 'NETWORK_ERROR',
                        details: 'No response from server',
                    },
                };
            }
        }
    };

    return {
        getLimitCoupons,
        getCoupons,
        getCouponsQuantity,
        getCouponCodes,
        deleteCoupon,
        updateCoupon,
        createCoupon
    };
}
import React, { createContext, useContext, useState } from 'react';
import useSubscriptionPriceService from '../services/subscriptionPriceService';

const SubscriptionPriceContext = createContext(null);

export const SubscriptionPriceProvider = ({ children }) => {
    const [subscriptionPrices, setSubscriptionPrices] = useState([]);
    const SubscriptionPriceService = useSubscriptionPriceService();

    const getSubscriptionPrices = async () => {
        try {
            const subscriptionPrices = await SubscriptionPriceService.getSubscriptionPrices();
            setSubscriptionPrices(subscriptionPrices);
        } catch (error) {
            console.error(
                'Hubo un error al intentar obtener los precios de las suscripciones: ',
                error
            );
        }
    };

    const getSubscriptionPrice = async countryCode => {
        try {
            const subscriptionPrice = await SubscriptionPriceService.getSubscriptionPrice(
                countryCode
            );
            return subscriptionPrice;
        } catch (error) {
            console.error(
                'Hubo un error al buscar el precio de la suscripcion: ',
                error
            );
        }
    };

    const updateSubscriptionPrice = async (countryCode, updatedSubscriptionPrice) => {
        try {
            const response = await SubscriptionPriceService.updateSubscriptionPrice(countryCode, updatedSubscriptionPrice);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar actualizar el precio de la suscripción: ',
                error
            );
        }
    };

    const deleteSubscriptionPrice = async countryCode => {
        try {
            const response = await SubscriptionPriceService.deleteSubscriptionPrice(countryCode);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar eliminar el precio de la suscripción: ',
                error
            );
        }
    };

    const createSubscriptionPrice = async subscriptionPrice => {
        try {
            const response = await SubscriptionPriceService.createSubscriptionPrice(subscriptionPrice);
            return response && response.success !== undefined ? response.success : null;
        } catch (error) {
            console.error(
                'Hubo un error al intentar crear el precio de la suscripción: ',
                error
            );
        }
    };

    return (
        <SubscriptionPriceContext.Provider
            value={{
                getSubscriptionPrices,
                getSubscriptionPrice,
                updateSubscriptionPrice,
                deleteSubscriptionPrice,
                createSubscriptionPrice,
                subscriptionPrices,
            }}
        >
            {children}
        </SubscriptionPriceContext.Provider>
    );
};

export const useSubscriptionPrice = () => useContext(SubscriptionPriceContext);

export default SubscriptionPriceProvider;
export default function decodeJWT(token) {
    try {
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('Invalid JWT token format');
        }

        const decodedHeader = JSON.parse(atob(parts[0]));
        const decodedPayload = JSON.parse(atob(parts[1]));

        return {
            header: decodedHeader,
            payload: decodedPayload
        };
    } catch (error) {
        console.error('Failed to decode JWT:', error);
        return null;
    }
}

/* // Example usage:
const token = 'your.jwt.token.here';
const decodedToken = decodeJWT(token);
console.log(decodedToken); */

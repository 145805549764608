import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';

const useAxiosConfig = () => {
  const { getToken, logout } = useAuth();
  const navigate = useNavigate();

  const onReqSend = useCallback(async (config) => {
    const token = await getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, [getToken]);

  const onReqError = useCallback((error) => {
    return Promise.reject(error);
  }, []);

  const onResSuccess = useCallback((response) => {
    return response;
  }, []);

  const onResError = useCallback((error) => {
    if (error.response && error.response.status === 401) {
      logout();
      navigate('/login');
    }
    return Promise.reject(error);
  }, [logout, navigate]);

  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(
      onReqSend,
      onReqError
    );
    const resInterceptor = axiosInstance.interceptors.response.use(
      onResSuccess,
      onResError
    );
  
    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return { axiosInstance };
};

export default useAxiosConfig;

import React, { createContext, useContext, useState } from 'react';
import useTutorialService from '../services/tutorialsService';

const TutorialContext = createContext(null);

export const TutorialProvider = ({ children }) => {
  const [tutorial, setTutorial] = useState([]);
  const tutorialService = useTutorialService();

  const getTutorials = async () => {
    try {
      const res = await tutorialService.getTutorials();
      if (res.status === 'success') {
        setTutorial(Array.isArray(res.data.tutorials) ? res.data.tutorials : []);
      } else {
        setTutorial([]); 
      }
    } catch (error) {
      console.log('Hubo un error al intentar obtener los tutoriales: ', error);
      setTutorial([]); 
    }
  };

  const addTutoriales = async (file, title, description, duration, order) => {
    try {
      console.log("Se va a enviar al back: " + file + ", " + title + ", " + description + ", " + duration + ", "+ order)
      await tutorialService.addTutorials(file, title, description, duration, order);
      await getTutorials();
    } catch (error) {
      console.log('Hubo un error al actualizar los tutoriales: ', error);
    }
  };
  const editTutoriales = async (file = null, title, description, duration, order, id) => {
    try {
      console.log("Se va a actualizar el tutorial con el id: " + id + "con la informacion: "+ file + ", " + title + ", " + description + ", " + duration + ", "+ order )
      await tutorialService.editTutorials(file, title, description, duration, order, id);
      await getTutorials();
    } catch (error) {
      console.log('Hubo un error al actualizar los tutoriales: ', error);
    }
  };
  const deleteTutoriales = async(id) => {
    try{
      console.log("Se va a eliminar el tutorial con id: " + id);
      await tutorialService.deleteTutorials(id);
      await getTutorials();
    } catch (error) {
      console.log('Hubo un error al eliminar el tutorial: ', error);
    }
  }

  return (
    <TutorialContext.Provider value={{ getTutorials, tutorial, addTutoriales,editTutoriales, deleteTutoriales }}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => useContext(TutorialContext);

export default TutorialProvider;

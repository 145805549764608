import React, { useState, useEffect } from 'react';
import { useSale } from '../../contexts/saleContext';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Heading,
  Spinner,
  Text,
  Flex,
  Box,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';

const Sales = () => {
  const {
    getSale,
    getLimitSales,
    getSalesQuantity,
    limitSales,
    setLimitSales,
    salesQuantity,
    setSalesQuantity,
  } = useSale();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSale, setSelectedSale] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const itemsPerPage = 10;

  const fetchSales = async page => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage;
    await getLimitSales(itemsPerPage, offset);
    await getSalesQuantity();
    setLoading(false);
  };

  useEffect(() => {
    fetchSales(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSearch = async () => {
    setLoading(true);
    const sale = await getSale(searchTerm);
    setLimitSales([sale]);
    setSalesQuantity(1);
    setLoading(false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    fetchSales(currentPage);
  };

  const handleOpenDetail = sale => {
    setSelectedSale(sale);
    onOpen();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && searchTerm !== '') {
      handleSearch();
    }
  };

  const totalPages = Math.ceil(salesQuantity / itemsPerPage);

  return (
    <Box m={8}>
      {loading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box>
          <Flex
            px="16px"
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: 'column', md: 'row' }}
          >
            <Heading as="h2" size="lg" textAlign="center">
              Ventas
            </Heading>
            <Flex
              my={4}
              alignItems="center"
              direction={{ base: 'column', md: 'row' }}
              w={{ base: '100%', md: 'auto' }}
            >
              <Input
                placeholder="Buscar por MP ID"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                width={{ base: '100%', md: '300px' }}
              />
              <Box>
                {searchTerm && (
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={handleClearSearch}
                    ml={{ base: 0, md: 2 }}
                    mt={{ base: 2, md: 0 }}
                    aria-label="Limpiar búsqueda"
                  />
                )}
                <Button
                  onClick={handleSearch}
                  colorScheme="teal"
                  ml={2}
                  mt={{ base: 2, md: 0 }}
                  isDisabled={!searchTerm.trim()}
                >
                  Buscar
                </Button>
              </Box>
            </Flex>
          </Flex>

          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Orden ID (MP ID)</Th>
                <Th>Nombre</Th>
                <Th>Apellido</Th>
                <Th>Monto</Th>
                <Th>Fecha</Th>
                <Th>Detalle</Th>
              </Tr>
            </Thead>
            <Tbody>
              {limitSales[0] ? (
                limitSales.map(sale => (
                  <Tr key={sale._id}>
                    <Td>{sale.mpId}</Td>
                    <Td>{sale.payer?.first_name || 'N/A'}</Td>
                    <Td>{sale.payer?.last_name || 'N/A'}</Td>
                    <Td>{'$' + sale.total_paid_amount}</Td>
                    <Td>{new Date(sale.date_approved).toLocaleDateString()}</Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        onClick={() => handleOpenDetail(sale)}
                      >
                        Ver Detalle
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No se han encontrado ventas con ese MP ID
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Flex justify="space-between" mt={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
            />
            <Text>
              {currentPage} de {totalPages}
            </Text>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() =>
                setCurrentPage(prev => Math.min(prev + 1, totalPages))
              }
              isDisabled={currentPage === totalPages}
            />
          </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Detalle de la Venta</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {selectedSale && (
                  <>
                    <Text>
                      <strong>Nombre:</strong>{' '}
                      {selectedSale.payer?.first_name || 'N/A'}
                    </Text>
                    <Text>
                      <strong>Apellido:</strong>{' '}
                      {selectedSale.payer?.last_name || 'N/A'}
                    </Text>
                    <Text>
                      <strong>MP ID:</strong> {selectedSale.mpId}
                    </Text>
                    <Text>
                      <strong>Monto:</strong> {selectedSale.total_paid_amount}
                    </Text>
                    <Text>
                      <strong>Fecha:</strong>{' '}
                      {new Date(
                        selectedSale.date_approved
                      ).toLocaleDateString()}
                    </Text>
                    <Text mt={4}>
                      <strong>Productos:</strong>
                    </Text>
                    {selectedSale.additional_info.items.map((item, index) => (
                      <Box
                        key={index}
                        p={2}
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        mb={2}
                      >
                        <Text>
                          <strong>Título:</strong> {item.title}
                        </Text>
                        <Text>
                          <strong>Precio Unitario:</strong> ${item.unit_price}
                        </Text>
                        <Text>
                          <strong>Cantidad:</strong> {item.quantity}
                        </Text>
                      </Box>
                    ))}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={onClose}>
                  Cerrar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </Box>
  );
};

export default Sales;

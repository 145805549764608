import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    HStack,
    Image,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useColorMode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBell, FiSearch } from 'react-icons/fi';
import { DesplegableDesktop } from '../molecules/DesplegableDesktop';
import logo_light from '../../assets/RIP_transp.png';
import logo_dark from '../../assets/RIP_transp.png';
import { MobileMenu } from './MobileMenu';
import { useAuth } from '../../auth/AuthContext';

export const Header = () => {
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();
    const [user, setUser] = useState(null);
    const { getUser, logout } = useAuth();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await getUser();
                setUser(userData);
            } catch (error) {
                console.error("Error fetching user", error);
            }
        };

        fetchUser();
    }, [getUser]);

    return (
        <Box borderBottomWidth="1px" bg={colorMode === 'light' ? 'white' : 'gray.800'}>
            <Box p={3}>
                <HStack justify="space-between" spacing={4}>
                    <HStack spacing={4}>
                        <MobileMenu />
                        <Image h="50px" src={colorMode === 'light' ? logo_light : logo_dark} />
                        <ButtonGroup
                            size="sm"
                            variant="ghost"
                            colorScheme="gray"
                            display={{ base: 'none', lg: 'flex' }}
                        >
                            <Button onClick={() => navigate('/tablero')}>Tablero</Button>
                            <Button onClick={() => navigate('/tienda')}>Tienda</Button>
                            <Button onClick={() => navigate('/ventas')}>Ventas</Button>
                            <Button onClick={() => navigate('/suscripciones')}>Suscripciones</Button>
                            <DesplegableDesktop />
                            <Button onClick={() => navigate('/tutoriales')}>Tutoriales</Button>
                            <Button onClick={() => navigate('/cupones')}>Cupones</Button>
                            <Button onClick={() => navigate('/usuarios')}>Usuarios</Button>
                            <Button onClick={() => navigate('/reportes')}>Reportes</Button>
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={4}>
                        <IconButton
                            icon={<FiSearch />}
                            aria-label="Buscar"
                            display={{ base: 'flex', lg: 'none' }}
                            variant="outline"
                        />
                        <IconButton
                            icon={<FiBell />}
                            aria-label="Notificaciones"
                            variant="outline"
                        />
                        <Menu>
                            <MenuButton as={Box} textAlign="center">
                                <Avatar size="sm" name={user?.name} />
                                <Box fontSize="sm">{user?.rol}</Box>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={toggleColorMode}>
                                    {colorMode === 'light' ? 'Modo Oscuro' : 'Modo Claro'}
                                </MenuItem>
                                <MenuItem onClick={logout}>Salir</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    );
};

import React, { useState, useEffect } from 'react';
import { useCoupon } from '../../contexts/couponContext';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Spinner,
    Text,
    Flex,
    Box,
    Input,
    IconButton,
    Heading,
    Select,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import CreateCouponModal from '../organisms/CreateCouponModal';
import DeleteCouponModal from '../organisms/DeleteCouponModal';
import UpdateCouponModal from '../organisms/UpdateCouponModal';
import DetailsCouponModal from '../organisms/DetailsCouponModal';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, EditIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';

const Coupons = () => {
    const {
        getLimitCoupons,
        getCoupons,
        getCouponsQuantity,
        couponsQuantity,
        limitCoupons,
        setLimitCoupons,
        setCouponsQuantity,
    } = useCoupon();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [foundationType, setFoundationType] = useState('empresa');
    const [isSearching, setIsSearching] = useState(false);

    const {
        isOpen: isDetailsOpen,
        onOpen: onDetailsOpen,
        onClose: onDetailsClose
    } = useDisclosure();

    const {
        isOpen: isCreateOpen,
        onOpen: onCreateOpen,
        onClose: onCreateClose,
    } = useDisclosure();

    const {
        isOpen: isUpdateOpen,
        onOpen: onUpdateOpen,
        onClose: onUpdateClose,
    } = useDisclosure();

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();

    const itemsPerPage = 10;

    const fetchCoupons = async page => {
        setLoading(true);
        const offset = (page - 1) * itemsPerPage;
        await getLimitCoupons(itemsPerPage, offset, foundationType);
        await getCouponsQuantity(foundationType);
        setLoading(false);
    };

    useEffect(() => {
        if (!isSearching) {
            fetchCoupons(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, foundationType]);

    const handleSearch = async () => {
        setLoading(true);
        setCurrentPage(1);
        setIsSearching(true);
        const coupons = await getCoupons(foundationType, searchTerm);
        setLimitCoupons(coupons);
        setCouponsQuantity(1);
        setIsSearching(false);
        setLoading(false);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setCurrentPage(1);
        fetchCoupons(1);
    };

    const handleOpenDetail = (couponId) => {
        setSelectedCoupon(couponId);
        onDetailsOpen();
    };

    const handleOpenUpdate = async (coupon) => {
        setSelectedCoupon(coupon);
        onUpdateOpen();
    };

    const handleOpenDelete = async (couponId) => {
        setSelectedCoupon(couponId);
        onDeleteOpen();
    };

    const handleOpenCreate = async () => {
        onCreateOpen();
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter' && searchTerm !== '') {
            handleSearch();
        }
    };

    const totalPages = Math.ceil(couponsQuantity / itemsPerPage);

    return (
        <Box m={8}>
            <Flex
                px="16px"
                justifyContent="space-between"
                alignItems="center"
                direction={{ base: 'column', lg: 'row' }}
            >
                <Heading as="h2" size="lg" textAlign={'center'}>
                    Cupones
                </Heading>
                <Flex
                    my={4}
                    alignItems="center"
                    direction={{ base: 'column', md: 'row' }}
                    w={{ base: '100%', md: 'auto' }}
                >
                    <Input
                        placeholder="Buscar por fundación"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                        width={{ base: '100%', md: '300px' }}
                    />
                    <Flex>
                        {searchTerm && (
                            <IconButton
                                icon={<CloseIcon />}
                                onClick={handleClearSearch}
                                ml={{ base: 0, md: 2 }}
                                mt={{ base: 2, md: 0 }}
                                aria-label="Limpiar búsqueda"
                            />
                        )}
                        <Flex flexDirection={{ base: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} >
                            <Flex>
                                <Button
                                    onClick={handleSearch}
                                    colorScheme="teal"
                                    ml={2}
                                    mt={{ base: 2, md: 0 }}
                                    isDisabled={!searchTerm.trim()}
                                >
                                    Buscar
                                </Button>
                                <Select
                                    value={foundationType}
                                    mt={{ base: 2, md: 0 }}
                                    onChange={e => { setFoundationType(e.target.value); setCurrentPage(1); }}
                                    ml={2}
                                    width="150px"
                                >
                                    <option value="gob">Gobierno</option>
                                    <option value="empresa">Empresa</option>
                                    <option value="otra">Otra</option>
                                </Select>
                            </Flex>
                            <Button colorScheme="green" ml={4} mt={{ base: 2, md: 0 }} onClick={handleOpenCreate}>
                                Crear Cupón
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {loading ? (
                <Flex justify="center" align="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
            ) : (
                <>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Fundación</Th>
                                <Th>Vía Fundación</Th>
                                <Th>% Descuento</Th>
                                <Th>Códigos Asociados</Th>
                                <Th>Tipo de Fundación</Th>
                                <Th>Fecha de Creación</Th>
                                <Th>Fecha de Expiración</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {limitCoupons[0] ? (
                                limitCoupons.map(coupon => (
                                    <Tr key={coupon._id}>
                                        <Td maxWidth={"350px"}>{coupon.fundacion}</Td>
                                        <Td maxWidth={"350px"}>{coupon.via_fundacion}</Td>
                                        <Td>{coupon.discount}%</Td>
                                        <Td>{coupon.associated_codes}</Td>
                                        <Td>
                                            <Text textTransform={'uppercase'}>
                                                {coupon.tipo_de_fundacion}
                                            </Text>
                                        </Td>
                                        <Td>{coupon.date_created}</Td>
                                        <Td>{coupon.expires_at}</Td>
                                        <Td>
                                            <Flex display={"flex"} flexDirection={"row"}>
                                                <Tooltip label="Ver Detalle" aria-label="Ver Detalle">
                                                    <IconButton
                                                        icon={<ViewIcon />}
                                                        onClick={() => handleOpenDetail(coupon._id)}
                                                        mr={2}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Eliminar" aria-label="Eliminar">
                                                    <IconButton
                                                        icon={<DeleteIcon />}
                                                        onClick={() => handleOpenDelete(coupon._id)}
                                                        mr={2}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Editar" aria-label="Editar">
                                                    <IconButton
                                                        icon={<EditIcon />}
                                                        onClick={() => handleOpenUpdate(coupon)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td colSpan="8" textAlign="center">
                                        No se han encontrado cupones con ese nombre de fundación
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <Flex justify="space-between" align="center" mt={4}>
                        <Button
                            onClick={() => setCurrentPage(currentPage - 1)}
                            isDisabled={currentPage === 1}
                            leftIcon={<ChevronLeftIcon />}
                        >
                            Anterior
                        </Button>
                        <Text>
                            Página {currentPage} de {totalPages}
                        </Text>
                        <Button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            isDisabled={currentPage === totalPages}
                            rightIcon={<ChevronRightIcon />}
                        >
                            Siguiente
                        </Button>
                    </Flex>

                    <DetailsCouponModal
                        isOpen={isDetailsOpen}
                        onClose={onDetailsClose}
                        couponId={selectedCoupon}
                    />

                    <CreateCouponModal
                        isOpen={isCreateOpen}
                        onClose={onCreateClose}
                        fetchCoupons={fetchCoupons}
                    />

                    <UpdateCouponModal
                        isOpen={isUpdateOpen}
                        onClose={onUpdateClose}
                        coupon={selectedCoupon}
                        fetchCoupons={fetchCoupons}
                    />

                    <DeleteCouponModal
                        isOpen={isDeleteOpen}
                        onClose={onDeleteClose}
                        couponId={selectedCoupon}
                        fetchCoupons={fetchCoupons}
                    />
                </>
            )}
        </Box>
    );
};

export default Coupons;

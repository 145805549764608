import { Button, Drawer, DrawerBody, DrawerContent, Stack, useDisclosure } from '@chakra-ui/react';
import { DesplegableMobile } from '../molecules/DesplegableMobile';
import { ButtonToggleMenu } from '../atoms/ButtonToggleMenu';
import { useNavigate } from 'react-router-dom';

export const MobileMenu = () => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        onClose();
    };

    return (
        <>
            <ButtonToggleMenu
                isOpen={isOpen}
                onClick={onToggle}
                aria-label="Open menu"
                display={{
                    base: 'inline-flex',
                    lg: 'none',
                }}
            />
            <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerBody p="4">
                        <Stack spacing="1">
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/tablero')}>
                                Tablero
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/tienda')}>
                                Tienda
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/ventas')}>
                                Ventas
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/suscripciones')}>
                                Suscripciones
                            </Button>
                            <DesplegableMobile handleNavigation={handleNavigation} />
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/tutoriales')}>
                                Tutoriales
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={onClose}>
                                Cupones
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={() => handleNavigation('/Usuarios')}>
                                Usuarios
                            </Button>
                            <Button size="lg" variant="tertiary" justifyContent="start" onClick={onClose}>
                                Reportes
                            </Button>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};
